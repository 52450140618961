<template>
<span v-if="resumen">
<br/>
<div >
  <div class="row" v-for="(item, index) in resumen" :key="index">
    <el-button v-show="getArea.area != null" plain size="large" type="primary" :key="index" @click="navigateToDestinos(item.TSTORI, item.CODUNI, item)" class="btn btn-primary mb-2">
      {{ item.FCHDES }} {{item.HoraCitacion}} <br>{{ item.Terminal }} <br>unidad: {{ item.CODUNI }}
    </el-button>
  </div>
</div>
</span>
</template>
<style scoped>
img {
  display: relative;
  max-width: 90%;
  height: auto;
}
.el-button {
  font-size: 36px !important;
}
</style>
<script>
import { mapGetters } from 'vuex'
import config from '../../public/config.json'
import axios from 'axios'
import shared from '../shared/shared'
export default {
  extends: shared,
  name: 'Home',
  computed: {
    ...mapGetters(['isLoggedIn']),
    ...mapGetters(['getToken']),
    ...mapGetters(['getArea']),
    ...mapGetters(['getDni'])
  },
  components: {
  },
  data () {
    return {
      resumen: [],
      editednotas: {
      },
      textoVisible: false,
      asignados: [],
      profesional: '',
      chkok: ''
    }
  },
  async mounted () {
    var self = this
    if (this.isLoggedIn) {
      const url = 'http://' + config.ip + '/comcam/choferesapp/pendientes/dni/params;dni=' + self.getDni
      await self.makeGetRequest(url, 0)
        .then(response => {
          self.resumen = JSON.parse(JSON.stringify(response).split('T00:00:00Z[-03:00]').join(''))
        })
        .catch(error => {
          console.log(error)
        })
    }
  },
  beforeMount () {
    document.title = 'Resumen del Viaje'
  },
  methods: {
    makeGetRequest (url, retryCount = 0) {
      const self = this
      return new Promise((resolve, reject) => {
        axios.get(url)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            if (retryCount < 3) {
              // Incrementar el contador de reintento
              const newRetryCount = retryCount + 1
              console.log(`Error en GET, reintentando (${newRetryCount})...`)
              // Esperar un breve período de tiempo antes de hacer el próximo intento
              setTimeout(() => {
                self.makeGetRequest(url, newRetryCount)
                  .then(response => {
                    resolve(response)
                  })
                  .catch(error => {
                    reject(error)
                  })
              }, 500) // Esperar 1 segundo antes de cada reintento
            } else {
              reject(error)
            }
          })
      })
    },
    async navigateToDestinos (tstori, unidad, item) {
      try {
        /* eslint-disable no-undef */
        AndroidInterface.evento('lugar:' + item.Terminal + ';unidad:' + unidad + ';tstamp:' + tstori)
        /* eslint-disable no-undef */
      } catch (error) {
        console.log('rtx: ' + error)
      }
      this.$router.push('/Destinos/?tstori=' + tstori + '&unidad=' + unidad)
    },
    highlightPend (text) {
      if (!text) return
      return text.replace(/^(--.*)$/gm, '<span style="color: yellow">$1</span>')
    },
    saveChanges () {
    }
  }
}
</script>
