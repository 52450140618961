<template>
<span v-if='chkok.STATUS && chkok.STATUS!=="OK"'>
  <br/>
  <h1> por favor realice checklist de toma de unidad </h1>
</span>
<span v-if='!showDialog && chkok.STATUS==="OK"'>
  <div v-if="destinos">
    <div v-for="destino in destinos" :key="destino.id" class="row">
      <div class="col-md-1 evento" :class="{'amarillo': destino.DESTINO.includes('[D]')}">
        <strong></strong> <br> {{ destino.DESTINO }}
      </div>
      <div class="col-md-1">
        <el-button-group>
          <el-button
            size="large"
            type="success"
            @click="marcarEvento(destino.NROCTA, destino.BOTON, destino.DESTINO)"
            plain
          >
            {{ destino.BOTON }}
          </el-button>
        </el-button-group>
      </div>
    </div>
    <el-divider></el-divider>
  </div>
  <div v-if="productos">
    <div v-for="producto in productos" :key="producto.id" class="row">
      <div class="col-md-3 evento">
        <s v-if="producto.CantidadRechazada !== 0">
          {{ producto.Producto }} - {{ producto.CANTID }} lts
        </s>
        <span v-else>
          {{ producto.Producto }} - {{ producto.CANTID }} lts
        </span>
      </div>
      <div class="col-md-3">
        <el-button-group>
          <el-button
            v-if="producto.CantidadRechazada === 0"
            size="large"
            type="danger"
            @click="marcarRechazo(producto)"
            plain
            disabled
          >
            Rechazo
          </el-button>
        </el-button-group>
      </div>
    </div>
    <el-divider></el-divider>
  </div>
</span>
<span v-if="showDialog">
  <div class="rechazo">
    <div class="row">
      <div class="col">
        {{destinos[0].DESTINO}}
      </div>
    </div>
    <div class="row">
      <div class="col">
        {{this.rechazo.producto}}
      </div>
    </div>
    <div class="row">
      <div class="col">
        {{this.rechazo.cantid}} lts
      </div>
    </div>
    <el-divider></el-divider>
    <div class="row">
    </div>
    <div class="row">
      <div class="col">
        <el-input class="evento" v-model='this.rechazo.rechazado' placeholder='cantidad rechazada'/>
      </div>
    </div>
    <br/>
    <el-button-group>
      <el-button
          size="large"
          type="success"
          @click="rechazar()"
          plain
        >
          continuar
      </el-button>
      <el-button
          size="large"
          type="danger"
          @click="this.showDialog = false"
          plain
        >
          cancelar
      </el-button>
    </el-button-group>
  </div>
</span>
</template>
<style>
img {
  display: relative;
  max-width: 90%;
  height: auto;
}
.evento{
  font-size: 36px !important;
}
.amarillo{
  color: yellow !important;
}
.rechazo{
  font-size: 20px !important;
}
.el-button {
  font-size: 36px !important;
}
.el-message-box__content{
 font-size: 24px !important;
}
.el-message-box__btns{
  text-align: center !important;
}
</style>
<script>
import { mapGetters } from 'vuex'
import config from '../../public/config.json'
import axios from 'axios'
import shared from '../shared/shared'
import { ElMessageBox } from 'element-plus'
export default {
  extends: shared,
  name: 'Home',
  props: {
    tstori: String,
    unidad: String
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
    ...mapGetters(['getToken']),
    ...mapGetters(['getDni'])
  },
  components: {
  },
  data () {
    return {
      chkok: '',
      rechazo: {
        artcod: '',
        tippro: '',
        producto: '',
        cantid: 0,
        rechazado: null
      },
      showDialog: false,
      destinos: null,
      productos: null,
      editednotas: {
      },
      textoVisible: false,
      asignados: [],
      profesional: ''
    }
  },
  mounted () {
    this.init()
  },
  beforeMount () {
    document.title = 'Destinos'
  },
  methods: {
    makeGetRequest (url, retryCount = 0) {
      const self = this
      return new Promise((resolve, reject) => {
        axios.get(url)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            if (retryCount < 3) {
              // Incrementar el contador de reintento
              const newRetryCount = retryCount + 1
              console.log(`Error en GET, reintentando (${newRetryCount})...`)
              // Esperar un breve período de tiempo antes de hacer el próximo intento
              setTimeout(() => {
                self.makeGetRequest(url, newRetryCount)
                  .then(response => {
                    resolve(response)
                  })
                  .catch(error => {
                    reject(error)
                  })
              }, 500) // Esperar 1 segundo antes de cada reintento
            } else {
              reject(error)
            }
          })
      })
    },
    async init () {
      this.productos = null
      var self = this
      if (this.isLoggedIn) {
        let url = 'http://' + config.ip + '/comcam/choferesapp/destinos/tstori/params;tstori=' + self.$route.query.tstori
        self.makeGetRequest(url)
          .then(response => {
            self.destinos = JSON.parse(JSON.stringify(response).split('T00:00:00Z[-03:00]').join(''))
            if (self.destinos.length > 0) {
              if (self.destinos[0].BOTON === 'FIN') {
                self.productosx(self.destinos[0].NROCTA)
              }
            } else {
              self.$router.go(-1)
            }
          })
          .catch(error => {
            console.log(error)
          })
        url = 'http://' + config.ip + '/comcam/choferesapp/chklist/status/params;uutt=' + self.$route.query.unidad + ';dni=' + self.getDni
        self.makeGetRequest(url)
          .then(response => {
            self.chkok = JSON.parse(JSON.stringify(response).split('T00:00:00Z[-03:00]').join(''))
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    rechazar () {
      const self = this
      ElMessageBox.confirm(
        'confirma rechazo?',
        '',
        {
          cancelButtonText: 'No',
          confirmButtonText: 'Si',
          type: 'warning',
          customClass: 'rechazo'
        }
      )
        .then(() => {
          const url = 'http://' + config.ip + '/comcam/choferesapp/destinos/rechazo/params;tst=' + self.$route.query.tstori + ';artcod=' + self.rechazo.artcod + ';tippro=' + self.rechazo.tippro + ';nrocta=' + self.destinos[0].NROCTA + ';cantid=' + self.rechazo.rechazado
          self.openFSL(self)
          fetch(url, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: self.getToken.token
            },
            body: ''
          })
            .then(async response => {
              if (response.status === 401) {
                self.errorMsg('permisos insuficientes para completar la operacion')
              } else {
                self.showDialog = false
                self.init()
              }
              self.closeFSL(self)
            })
            .catch(error => {
              console.error('Error al grabar: ', error)
              self.errorMsg('Error al grabar')
              self.closeFSL(self)
            })
        })
        .catch(() => {
          self.showDialog = false
        })
    },
    marcarRechazo (producto) {
      this.rechazo.artcod = producto.ARTCOD
      this.rechazo.tippro = producto.TIPPRO
      this.rechazo.producto = producto.Producto
      this.rechazo.cantid = producto.CANTID
      this.showDialog = true
    },
    marcarEvento (nrocta, boton, destino) {
      const self = this
      const url = 'http://' + config.ip + '/comcam/choferesapp/destinos/llegueterminal/params;tst=' + self.$route.query.tstori + ';tipoevento=' + boton + ';nrocta=' + nrocta
      this.openFSL(this)
      fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: this.getToken.token
        },
        body: ''
      })
        .then(async response => {
          if (response.status === 401) {
            this.errorMsg('permisos insuficientes para completar la operacion')
          } else {
            this.init()
            try {
              /* eslint-disable no-undef */
              AndroidInterface.evento('destino:' + destino + '|opcion:' + boton + '|tstamp:' + self.$route.query.tstori + '|nrocta:' + nrocta)
              /* eslint-disable no-undef */
            } catch (error) {
              console.log('rtx: ' + error)
            }
          }
          this.closeFSL(this)
        })
        .catch(error => {
          console.error('Error al grabar: ', error)
          this.errorMsg('Error al grabar')
          this.closeFSL(this)
        })
    },
    productosx (nrocta) {
      // se llama luego de marcar un evento
      var self = this
      self.openFSL(self)
      self.closeFSL(self)
      self.destino = self.$route.query.descripcion
      if (this.isLoggedIn) {
        const url = 'http://' + config.ip + '/comcam/choferesapp/productos/params;tstori=' + self.$route.query.tstori + ';nrocta=' + nrocta
        axios.get(url, {
          dataType: 'json',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: self.getToken.token
          },
          mode: 'no-cors',
          credentials: 'include'
        })
          .then(function (response) {
            self.productos = JSON.parse(JSON.stringify(response.data).split('T00:00:00Z[-03:00]').join(''))
            self.closeFSL(self)
          })
          .catch(function (error) {
            console.log(error)
            self.closeFSL(self)
          })
      }
    },
    highlightPend (text) {
      if (!text) return
      return text.replace(/^(--.*)$/gm, '<span style="color: yellow">$1</span>')
    },
    saveChanges () {
    }
  }
}
</script>
