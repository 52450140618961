<template>
  <el-button type = "primary" @click="back()"><el-icon size="40"><ArrowLeftBold /></el-icon></el-button>
  <el-button type = "primary" @click="this.$router.push('/Home')"><el-icon size="40"><HomeFilled /></el-icon></el-button>
  <el-button type = "primary" @click="this.$router.push('/Home')"><el-icon size="40"><HomeFilled /></el-icon></el-button>
</template>
<style scoped>
img {
  display: relative;
  max-width: 90%;
  height: auto;
}
.el-button {
  font-size: 36px !important;
}
</style>
<script>
import router from '../router'
export default {
  name: 'back',
  methods: {
    back () {
      router.go(-1)
    },
    forward () {
      router.go(1)
    },
    reloadPage () {
      window.location.reload()
    }
  }
}
</script>
